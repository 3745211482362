<template>
  <div class="home">
    <v-row no-gutters>
      <v-col v-show="showpanel" cols="12" :sm="banner" class="spaces">
        <div>
          <v-carousel
            cycle
            :show-arrows="false"
            hide-delimiter-background
            delimiter-icon="mdi-minus"
            :height="windowHeight"
          >
            <v-carousel-item
              v-for="(item, i) in slides"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
        </div>
      </v-col>
      <v-col cols="12" :sm="logon" class="spaces">
        <div class="login">
          <v-card flat outlined rounded="0">
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-img
                :src="require('../assets/images/logo.png')"
                height="35"
                contain
              ></v-img>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-toolbar flat style="padding: 0px; margin: 0px">
              <v-spacer></v-spacer>
              <v-toolbar-title>Change Password</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="entry.password"
                label="new password"
                :type="showCurrent ? 'text' : 'password'"
                :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showCurrent = !showCurrent"
              ></v-text-field>
              <v-text-field
                v-model="entry.repassword"
                label="repeat new password"
                :type="showpass ? 'text' : 'password'"
                :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showpass = !showpass"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="go"
                :loading="loginx"
                depressed
                block
                :disabled="$v.entry.$invalid"
              >
                submit
              </v-btn>
            </v-card-actions>
            <div class="responseMessage">{{ responseMessage }}</div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
// import ForgotDialog from "./../components/accounts/entry/forgotton";
export default {
  props: {
    query: {
      type: String,
      default: null,
      required: true,
    },
  },
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      responseMessage: null,
      showpass: false,
      showCurrent: false,
      cycle: true,
      slides: [
        {
          src: require("../assets/images/caro1.png"),
        },
        {
          src: require("../assets/images/caro2.png"),
        },
        {
          src: require("../assets/images/caro3.png"),
        },
        {
          src: require("../assets/images/caro4.png"),
        },
      ],
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      loginx: false,
      banner: 6,
      logon: 6,
      txt: "",
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ...",
      },
      entry: {
        password: null,
        repassword: null,
      },
    };
  },
  validations: {
    entry: {
      password: {
        required,
        minLength: minLength(7),
      },
      repassword: {
        required,
        repeatPass: sameAs("password"),
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    showpanel: {
      get() {
        if (this.windowWidth <= 600) {
          return false;
        } else {
          return true;
        }
      },
      set(val) {
        return val;
      },
    },
  },
  watch: {
    windowWidth: function (value) {
      console.log(value);
      if (value <= 600) {
        console.log("less than");
        this.banner = 0;
        this.logon = 12;
        this.showpanel = false;
      } else {
        console.log("greater than");
        this.banner = 6;
        this.logon = 6;
        this.showpanel = true;
      }
    },
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    go() {
      this.loginx = true;
      // console.log(this.entry);
      // console.log(this.query);
      let password = this.entry.password;
      let secret = this.query;
      let data = { password, secret };
      console.log(data);

      let self = this;
      Restful.accounts.entry
        .changePassword(data)
        .then((response) => {
          this.snack.bar = true;
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.responseMessage = response.data;
          setTimeout(() => {
            this.$router.push({
              name: "home",
            });
          }, 3000);
        })
        .catch((error) => {
          // console.log(error.response);
          // console.log(error.response.data.message);
          self.responseMessage = error.response.data;
          self.loginx = false;
        });
    },
  },
};
</script>
